<template>
  <v-main>
    <v-container>
      <LoginComponent />
    </v-container>
  </v-main>
</template>

<script>
import LoginComponent from "../components/Login/LoginComponent.vue";
export default {
  components: {
    LoginComponent,
  },
};
</script>

<style>
</style>>