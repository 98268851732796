<template>
        <LoginMain/>
</template>

<script>
import LoginMain from './LoginMain.vue';
export default {
    components: {
        LoginMain,
    }
}
</script>

<style>

</style>